import {Colors} from "./Colors";
import {TypographySchemas} from "./Typography/typography";

export const DefaultTheme = {
    Colors: {
        generalDark: Colors.generalDark,
        generalDarkHover: Colors.generalDarkHover,
        generalLight: Colors.generalWhite,
        secondaryDefault: Colors.secondaryGray,
        secondaryDark: Colors.secondaryDarkGray,
        secondaryLight: Colors.hoverGray,
        accentPositive: Colors.green,
        accentPositiveHover: Colors.hoverGreen,
        accentNegative: Colors.pink,
        accentNegativeHover: Colors.hoverPink,
        borderDefault: Colors.borderGray,
        borderDefaultHover: Colors.secondaryDarkGray,
    },
    Typography: {
        schemas: {
            h1: TypographySchemas.bold72,
            h2: TypographySchemas.extraBold40,
            h3: TypographySchemas.bold32,
            h4: TypographySchemas.bold24,
            h5: TypographySchemas.semi24,
            title: TypographySchemas.semi16,
            bodyAccent: TypographySchemas.bold14,
            bodySubAccent: TypographySchemas.semi14,
            body: TypographySchemas.reg14,
            noteAccent: TypographySchemas.semibold12,
            note: TypographySchemas.reg12,
            annotation: TypographySchemas.reg10,
        },
    },
    Inputs: {},
    Button: {}
}
