import { TModalConfig } from '../components/Modal/Modal';
import { lazyInject, provide } from '../../../shared/utils/IoC';
import { ModalsStore, IModalPayload } from '../stores/modal.store';

@provide.singleton()
export class ModalController {
  @lazyInject(ModalsStore)
  protected modalStore: ModalsStore;

  setModalList = (uniqueKey: string, modalList: TModalConfig[]): void => {
    this.modalStore.setModalList(uniqueKey, modalList);
  };

  openModalByModalId = (modalId: TModalConfig['id']): void => {
    this.modalStore.setActiveModalId(modalId);
  };

  closeModal = (): void => {
    this.modalStore.clearActiveModalId();
  };

  setModalPayload = (payload: IModalPayload) => {
    this.modalStore.setModalPayload(payload);
  };

  getModalPayload = (): IModalPayload | null =>
    this.modalStore.getModalPayload();

  getActiveModalConfig = (uniqueKey?: string): TModalConfig | null => {
    if (uniqueKey) {
      return (
        this.modalStore
          .getModalList(uniqueKey)
          ?.find((modal) => modal.id === this.modalStore.getActiveModalId()) ||
        null
      );
    } else {
      return (
        this.modalStore
          .getAllModalsLists()
          ?.find((modal) => modal.id === this.modalStore.getActiveModalId()) ||
        null
      );
    }
  };
}
