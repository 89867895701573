import {IconNames, IconType} from './Icon.enum';
import React, {FC} from 'react';
import {StyledWrapperProps, Wrapper} from './style';

export type IconProps = {
  icon: IconNames;
  onClick?: () => void;
  dataTestId?: string;
} & StyledWrapperProps;

export const Icon: FC<IconProps> = ({size, icon, fill, onClick, dataTestId}) => {
  return (
    <Wrapper size={size} fill={fill} onClick={onClick} data-test-id={dataTestId}>
      {IconType[icon]}
    </Wrapper>
  )
}

export default Icon;