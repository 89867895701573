import styled from 'styled-components';
import {Colors} from '../configs/Colors';
import { IconNames } from '../Icon/Icon.enum';

export type InputFieldStyledProps = {
    error?: string;
    focus?: boolean;
    startIcon?: IconNames;
    endIcon?: IconNames;
    blocked?: boolean;
    isInGroupRight?: boolean;
    isInGroupLeft?: boolean;
}

const defaultScheme = `
 border-color: ${Colors.borderGray};
 color: ${Colors.generalDark};
 
 &:hover {
    border-color: ${Colors.secondaryDarkGray}
 }
`;

const disabledScheme = `
 border-color: ${Colors.borderGray};
 color: ${Colors.secondaryDarkGray};
`;

const hasErrorScheme = `
 border-color: ${Colors.pink};
 color: ${Colors.pink};
`;

const startPadding = `
 padding-left: 38px;
`;

const isInGroupRightState = `
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: none;
`;

const isInGroupLeftState = `
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: none;
`;

const renderColor = (error: boolean, disabled: boolean) => {
    if (disabled) return disabledScheme;

    return error ? hasErrorScheme : defaultScheme;
};

const addStartPadding = (startIcon: boolean) => {
    if (startIcon) {
        return startPadding;
    }

    return ``;
};

export const InputField = styled.input<InputFieldStyledProps>`
  background: ${props => props.disabled ? '#ffffff' : Colors.secondaryGray};
  color: ${props => props.disabled ? Colors.secondaryDarkGray : Colors.generalDark};
  border: 1px solid;
  border-radius: 14px;
  font-size: 12px;
  width: 100%;
  height: 40px;
  padding: 12px 16px;
  box-sizing: border-box;
  outline: none;

  ${({ error, disabled = false }) => renderColor(Boolean(error), disabled)};
  
  ${({ blocked}) => blocked && `color: ${Colors.generalDark};`}
  
  ${({ startIcon }) => addStartPadding(!!startIcon)};
  ${({ endIcon }) => endIcon && `padding-right: 38px;`}
  
  ${({isInGroupLeft}) => isInGroupLeft && isInGroupLeftState}
  ${({isInGroupRight}) => isInGroupRight && isInGroupRightState}
  
  ::placeholder {
    color: ${Colors.secondaryDarkGray};
  }
`;

export const ErrorMessage = styled.div`
  color: ${Colors.pink};
  font-size: 12px;
  padding: 4px 0;
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const Label = styled.p<{required?: boolean}>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.secondaryDarkGray};
  margin: 0 0 4px;
  &:after {
    color: red;
    visibility: ${({required}) => required ? 'visible' : 'hidden'};
    content: '*';
  }
`

type IconWrapperProps = {
    align: 'start' | 'end',
    isLabel: boolean
}

export const IconWrapper = styled.div<IconWrapperProps>`
  position: absolute;
  height: 40px;
  display: flex;
  align-items: center;
  ${({isLabel}) => isLabel ? `top: 20px;` : `top: 0;`}
  ${({align}) => align === 'start' ? `left: 16px;` : `right: 16px;`}
`;

export const TopBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ActionLabel = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: #3FB65F;
  cursor: pointer;
`;

export const ActionLabelImg = styled.img`
  margin-left: 5px;
`;
