import * as React from 'react';

const SvgMinus = (props: React.SVGProps<any>) => (
  <svg viewBox="0 0 8 2" fill="none" {...props}>
    <path
      d="M7.22581 0L0.774194 0C0.348387 0 0 0.45 0 1C0 1.55 0.348387 2 0.774194 2L7.22581 2C7.65161 2 8 1.55 8 1C8 0.45 7.65161 0 7.22581 0Z"
      fill="#151F32"
    />
  </svg>
);

export default SvgMinus;
