import React, { forwardRef, memo } from 'react';

import { Avatar } from '../Avatar';
import { getInitials } from '../../utils';
import { CardItemProps } from '../../types';

import * as Styled from './styles';

const CardItem = forwardRef<HTMLDivElement, CardItemProps>(
  ({ card: { avatar, content, dataTestId, styledProps }, ...styled }, ref) => {
    return (
      <Styled.CardItemWrapper
        {...styled}
        {...styledProps}
        data-test-id={dataTestId ? dataTestId : `card-item`}
        ref={ref}
      >
        {avatar && (
          <Styled.CardItemAvatarWrapper {...styledProps} text={avatar.title}>
            {avatar.src ? (
              <Avatar
                src={avatar.src}
                alt={avatar.alt || avatar.title}
                dataTestId={dataTestId && `${dataTestId}-card-item-avatar`}
              />
            ) : (
              <Styled.CardItemAvatarTitle
                data-test-id={dataTestId && `${dataTestId}-card-item-avatar-title`}
              >
                {getInitials(avatar.title, avatar.countSymbolsName)}
              </Styled.CardItemAvatarTitle>
            )}
          </Styled.CardItemAvatarWrapper>
        )}

        <Styled.CardItemContentWrapper
          {...styledProps}
          data-test-id={dataTestId && `${dataTestId}-card-item-content-wrapper`}
        >
          {content.title && (
            <Styled.CardItemContentTitle
              {...styledProps}
              data-test-id={dataTestId && `${dataTestId}-card-item-content-title`}
            >
              {content.title}
            </Styled.CardItemContentTitle>
          )}

          {content.subTitle && (
            <Styled.CardItemContentSubTitle
              data-test-id={dataTestId && `${dataTestId}-card-item-content-subtitle`}
            >
              {content.subTitle}
            </Styled.CardItemContentSubTitle>
          )}
        </Styled.CardItemContentWrapper>
      </Styled.CardItemWrapper>
    );
  }
);

export default memo(CardItem);
