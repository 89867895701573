import styled from 'styled-components';

export type StyledWrapperProps = {
  size: 'xsmall' | 'small' | 'medium' | 'large';
  fill?: string
}

const renderSize = (size: StyledWrapperProps['size']) => {
  const variants: Record<StyledWrapperProps['size'], string> = {
    large: `width: 24px;
            height: 24px;
            svg {
              width: 100%;
              height: auto;
            }
            `,
    medium: `
            width: 16px; 
            height: 16px;
            svg {
              width: 100%;
              height: auto;
            }
            `,
    small: `
            width: 12px; 
            height: 12px;
            svg {
              width: fit-content; 
              height: fit-content;
            }
            `,
    xsmall: `
            width: 8px; 
            height: 8px;
            svg {
              width: fit-content; 
              height: fit-content;
            }
            `,
  }

  return variants[size]
}

const renderFill = (fill: StyledWrapperProps['fill']) => fill && `* { fill: ${fill} ;}`

export const Wrapper = styled.div<StyledWrapperProps>`
  ${({size}) => renderSize(size)};
  ${({fill}) => renderFill(fill)};
  display: flex;
  justify-content: center;
  align-items: center;
`;
