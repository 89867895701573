import { observer } from 'mobx-react-lite';
import React, { FC, useCallback } from 'react';
import { Modal } from '../../components/Modal';
import { PropsStyledModal } from '../../components/Modal/styles';

import * as Styled from '../../components/Modal/styles';

import { useModal } from '../../hooks';

export type ModalContainerProps = {} & PropsStyledModal;

const ModalContainer: FC<ModalContainerProps> = observer(({ ...styled }) => {
  const { getActiveModalConfig, closeModal } = useModal();

  const closeModalHandler = useCallback((): void => {
    closeModal();
  }, []);

  const activeModal = getActiveModalConfig();
  if (activeModal) {
    return (
      <Styled.ModalWrapper>
        <Modal
          modal={activeModal}
          closeModalHandler={closeModalHandler}
          {...styled}
        />
      </Styled.ModalWrapper>
    );
  }
  return null;
});

export default ModalContainer;
