import { useStore } from '../../../shared/utils/IoC';
import { TModalConfig } from '../components/Modal/Modal';
import { ModalController } from '../controllers';
import { IModalPayload } from '../stores';

export const useModal = () => {
  const modalController = useStore(ModalController);

  const registerModalList = (
    modalsList: TModalConfig[],
    uniqueKey: string
  ): void => {
    modalController.setModalList(uniqueKey, modalsList);
  };

  const openModalByModalId = (
    modalId: TModalConfig['id'],
    payload?: IModalPayload
  ): void => {
    if (localStorage.getItem(`${modalId}-dont-show-again`) === 'true') return;

    if (payload) modalController.setModalPayload(payload);
    modalController.openModalByModalId(modalId);
  };

  const getActiveModalConfig = (uniqueKey?: string): TModalConfig | null => {
    return modalController.getActiveModalConfig(uniqueKey);
  };

  const closeModal = (): void => {
    modalController.closeModal();
  };

  const getModalPayload = (): IModalPayload | null =>
    modalController.getModalPayload();

  return {
    openModalByModalId,
    registerModalList,
    getActiveModalConfig,
    closeModal,
    getModalPayload,
  };
};
