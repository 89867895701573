import styled, { css } from 'styled-components';
import { Colors } from '../configs/Colors';

export const StyledSwitcherWrapper = styled.div`
  position: relative;
`;

export const StyledSwitcherCheckbox = styled.input`
  visibility: hidden;
  position: absolute;
`;

type StyledSwitcherElementProps = {
  $isActive?: boolean;
  $isBlocked?: boolean;
  $isLarge?: boolean;
};

export const StyledSwitcherElementCircle = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${Colors.generalWhite};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.21), 0px 3px 2px rgba(0, 0, 0, 0.09);
`;

export const StyledSwitcherElementEllipse = styled.div<StyledSwitcherElementProps>`
  box-sizing: border-box;
  padding: 2px;
  display: flex;
  cursor: ${({ $isBlocked }) => ($isBlocked ? 'auto' : 'pointer')};
  justify-content: ${({ $isActive }) => ($isActive ? 'flex-end' : 'flex-start')};
  opacity: ${({ $isBlocked }) => ($isBlocked ? '0.5' : '1')};
  width: ${({ $isLarge }) => ($isLarge ? '52' : '40')}px;
  height: ${({ $isLarge }) => ($isLarge ? '32' : '24')}px;
  border-radius: ${({ $isLarge }) => ($isLarge ? '16' : '12')}px;
  background-color: ${({ $isActive }) =>
    $isActive ? Colors.green : Colors.borderGray};

  ${StyledSwitcherElementCircle} {
    ${({ $isBlocked }) =>
      $isBlocked &&
      css`
        cursor: auto;
      `}

    ${({ $isLarge }) =>
      $isLarge &&
      css`
        width: 28px;
        height: 28px;
      `}
  }
`;
