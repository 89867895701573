import styled from 'styled-components';
import {Colors} from '../configs/Colors';

export type ButtonStyledProps = {
  color: "primary" | "secondary" | "default";
  alignment?: 'left' | 'center' | 'right';
  styleType?: 'filled' | 'outlined';
  isLoading?: boolean;
  disabled?: boolean;
}

const ButtonColorsMatrix = {
  primary: Colors.green,
  secondary: Colors.pink,
  default: Colors.secondaryDarkGray,
};

const renderButtonStyle = ({
  disabled,
  isLoading,
  color,
  styleType,
}: {
  disabled: boolean;
  isLoading: boolean;
  color: ButtonStyledProps['color'];
  styleType: ButtonStyledProps['styleType'];
}) => {
 if (disabled || isLoading) return `
    border: 1px solid ${Colors.borderGray};
    background-color: ${styleType === 'outlined' ? Colors.generalWhite : Colors.borderGray};
    color: ${styleType === 'outlined' ? Colors.borderGray : Colors.generalWhite};
    cursor: not-allowed;
 `;

 return `
    border: 1px solid ${ButtonColorsMatrix[color]};
    background-color: ${styleType === 'outlined' ? Colors.generalWhite : ButtonColorsMatrix[color]};
    color: ${styleType === 'outlined' ? ButtonColorsMatrix[color] : Colors.generalWhite};
 `;
}

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.16);
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.button<ButtonStyledProps>`
  height: 56px;
  border-radius: 20px;
  padding: 8px;
  display: flex;
  align-items: center;
  width: 100%;
  border: none;
  outline: none;
  user-select: none;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  ${({
    disabled,
    isLoading,
    color,
    styleType
  }) => renderButtonStyle({
    disabled: disabled || false,
    isLoading: isLoading || false,
    color,
    styleType,
   })}
  ${({alignment}) => alignment && `text-align: ${alignment};`};
  
  & ${IconWrapper} {
    margin-right: 20px;
  }
`;
