import React, {FC} from 'react';
import {Label, LinkStyledProps, Wrapper} from './style';
import {Icon} from '../Icon';
import {IconNames} from '../Icon/Icon.enum';

export type LinkProps = {
  label: string;
  onClick: () => void;
  prefixIcon?: IconNames;
  postfixIcon?: IconNames;
  underlined?: boolean;
  dataTestId?: string;
  className?: string;
} & LinkStyledProps;

const Link: FC<LinkProps> = ({className, label, color, onClick, prefixIcon, postfixIcon, underlined, dataTestId }) => {
  return (
    <Wrapper className={className} color={color} onClick={onClick} underlined={underlined} data-test-id={dataTestId}>
      {prefixIcon && <Icon icon={prefixIcon} size={'small'}/>}
      <Label>{label}</Label>
      {postfixIcon && <Icon icon={postfixIcon} size={'small'}/>}
    </Wrapper>
  )
}

export default Link;
