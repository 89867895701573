import React, { FC, useState, useEffect } from 'react';
import {
    Field, Label, Placeholder,
    SelectBody, SelectBodyInnerWrapper,
    Value, Wrapper, Option, ItemText, Error
} from './style';
import {Icon} from '../Icon';
import {Colors} from '../configs/Colors';

type OptionType = {
    label: string;
    value: string;
};

export type SelectProps = {
    label?: string;
    defaultValue?: OptionType;
    placeholder?: string;
    options: OptionType[];
    onChange: (v: string, item?: OptionType) => void;
    error?: string;
    disabled?: boolean;
    blocked?: boolean;
    dataTestId?: string;
    isInGroupRight?: boolean;
    isInGroupLeft?: boolean;
}

const Select: FC<SelectProps> = ({
                                     label,
                                     placeholder,
                                     defaultValue,
                                     options,
                                     onChange,
                                     error,
                                     disabled,
                                     dataTestId,
                                     blocked = false,
                                     isInGroupRight,
                                     isInGroupLeft
                                 }) => {

    const [value, setValue] = useState<OptionType | null>(null);
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const [isErrorShown, setIsErrorShown] = useState(false);

    useEffect(() => {
        defaultValue && setValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
        setIsErrorShown(Boolean(error));
    }, [error])

    const handleClick = () => {
        if(disabled || blocked) return;
        setIsOpened(!isOpened);
    }

    const handleSelectOption = (item: OptionType) => {
        setValue(item);
        onChange && onChange(item.value, item);
        setIsOpened(false);
    }

    const renderOptions = (options: OptionType[]) => options && options.map((item) => (
      <Option onClick={() => handleSelectOption(item)} key={`option-${item.value}`} data-test-id={`${dataTestId}_click-item`}>
          <ItemText>
              {item.label}
          </ItemText>
      </Option>
    ));

    const optionsWithoutSelected = options.filter(item => item.value !== value?.value);

    const handleOnBlur = () => {
      setIsOpened(false);
    }

    return (
        <Wrapper blocked={blocked} isOpened={isOpened} disabled={disabled} isErrorVisible={isErrorShown} tabIndex={0} onBlur={handleOnBlur} data-test-id={dataTestId} data-is-open={isOpened}>
            {label ? (
                <Label>
                    {label}
                </Label>
            ) : ''}
            <Field
                blocked={blocked}
                onClick={handleClick}
                isOpened={isOpened}
                data-test-id={`${dataTestId}_click-action`}
                isInGroupLeft={isInGroupLeft}
                isInGroupRight={isInGroupRight}
            >
                {
                    (value && value.label && value.value)
                        ? <Value data-test-id={`${dataTestId}_click-value`}>{value.label}</Value>
                        : placeholder && (
                          <Placeholder>
                            {placeholder}
                          </Placeholder>
                    )
                }
                {blocked ?
                    <Icon fill={Colors.secondaryDarkGray} icon={ 'minus' } size={ 'xsmall' }/> : <Icon size={'small'} icon={'arrow_down'} fill={Colors.secondaryDarkGray}/>
                }

            </Field>
            {isOpened && (
              <SelectBody>
                  <SelectBodyInnerWrapper>
                      {renderOptions(optionsWithoutSelected)}
                  </SelectBodyInnerWrapper>
              </SelectBody>
            )}
            {isErrorShown && error && !isOpened && (<Error>{error}</Error>)}
        </Wrapper>
    );
}

export default Select;
