import styled from 'styled-components';
import { CardItemStyledProps } from '../../types'
import { getSizeAvatar, getWidthCardItemContent, getFontsCardItemTitle, getAvatarColor } from '../../utils'
import { Colors } from '../../../configs/Colors';

export const CardItemWrapper = styled.div<CardItemStyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  width: ${({ offsetWidth }) => offsetWidth ? `calc(100% - ${offsetWidth})` : '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom ? marginBottom : '0'};
`;

export const CardItemAvatarWrapper = styled.div<{ text: string } & CardItemStyledProps>`
  box-sizing: border-box;
  display: inline-flex;
  font-weight: 700;  
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  overflow: hidden;

  ${({ size }) => getSizeAvatar(size)};
  ${({ text }) => getAvatarColor(text)};

  img,
  svg {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const CardItemAvatarTitle = styled.div``;

export const CardItemContentWrapper = styled.div<CardItemStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ size }) => getWidthCardItemContent(size)};
`;

export const CardItemContentTitle = styled.div<CardItemStyledProps>`
  width: 100%;
  color: ${Colors.generalDark};

  ${({ size }) => getFontsCardItemTitle(size)};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardItemContentSubTitle = styled.div`
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: ${Colors.darkGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
