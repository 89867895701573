import React, { FC } from 'react';
import {
  InputField,
  ErrorMessage,
  InputFieldStyledProps,
  InputWrapper,
  Label,
  IconWrapper,
  TopBlock,
  ActionLabel,
  ActionLabelImg
} from './style';
import { Icon } from '../Icon';

import editIcon from './edit.svg';
import {Colors} from "../configs/Colors";

export type InputProps = {
    label?: string;
    value?: string | number;
    type?: 'text' | 'number' | 'email' | 'tel' | 'password';
    onChange?: (value: string) => void;
    placeholder?: string;
    onClick?: () => void;
    disabled?: boolean;
    blocked?: boolean;
    maxLength?: number
    onFocus?: () => void;
    onBlur?: () => void;
    onEndIconClick?: () => void;
    dataTestId?: string;
    className?: string;
    required?: boolean;
    actionButton?: {
        label: string;
        onClick: () => void;
    }
    isInGroupRight?: boolean;
    isInGroupLeft?: boolean;
} & InputFieldStyledProps;

const MAXIMAL_LENGTH_DEFAULT = 100;

const Input: FC<InputProps> = ({
                                   className,
                                   placeholder,
                                   value,
                                   label,
                                   type = 'text',
                                   error,
                                   onChange,
                                   startIcon,
                                   onClick,
                                   disabled,
                                   maxLength,
                                   onFocus,
                                   onBlur,
                                   endIcon,
                                   onEndIconClick,
                                   dataTestId,
                                   required,
                                   actionButton,
                                   blocked = false,
                                   isInGroupLeft,
                                   isInGroupRight
                               }) => {
    const handleChange = (event: { target: { value: React.ReactText; }; }) => {
        if (onChange) {
            onChange(event.target.value.toString());
        }
    };

    return (
        <InputWrapper>
            <TopBlock>
                { label && <Label required={required}>{ label }</Label> }
                {actionButton ? (<ActionLabel onClick={actionButton?.onClick}>
                    {actionButton?.label}
                    <ActionLabelImg src={editIcon}/>
                </ActionLabel>) : null}
            </TopBlock>
            <InputField
                className={className}
                disabled={ disabled || blocked }
                blocked={blocked}
                onClick={ onClick }
                startIcon={ startIcon }
                endIcon={blocked ? 'minus' : undefined}
                value={ value }
                placeholder={ placeholder }
                type={ type }
                error={ error }
                onChange={ handleChange }
                maxLength={maxLength ? maxLength : MAXIMAL_LENGTH_DEFAULT}
                onFocus={onFocus}
                onBlur={onBlur}
                data-test-id={dataTestId}
                isInGroupLeft={isInGroupLeft}
                isInGroupRight={isInGroupRight}
            />
            { startIcon && (
                <IconWrapper isLabel={!!label} align={'start'}>
                    <Icon icon={ startIcon } size={ 'small' }/>
                </IconWrapper>
            ) }
          { endIcon && !blocked && (
            <IconWrapper isLabel={!!label} align={'end'} onClick={onEndIconClick}>
              <Icon icon={ endIcon } size={ 'small' }/>
            </IconWrapper>
          ) }

          { blocked && (
            <IconWrapper isLabel={!!label} align={'end'} onClick={onEndIconClick}>
              <Icon fill={Colors.secondaryDarkGray} icon={ 'minus' } size={ 'xsmall' }/>
            </IconWrapper>
          ) }
            { error && (<ErrorMessage data-test-id={`${dataTestId}_errorMessage`}>{ error }</ErrorMessage>) }
        </InputWrapper>
    )
};

export default Input;
