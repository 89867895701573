export enum ECardItemSize {
  Medium = '24px',
  Large = '32px',
}

export type CardItemStyledProps = {
  size?: ECardItemSize;
  offsetWidth?: string;
  marginBottom?: string;
};

export type TCardItemAvatar = {
  title: string;
  src?: string;
  alt?: string;
  countSymbolsName?: number;
};

export type TCardItemContant = {
  title?: string;
  subTitle?: string;
};

export type TCardItemConfig = {
  avatar: TCardItemAvatar;
  content: TCardItemContant;
  styledProps: CardItemStyledProps;
  dataTestId?: string;
};

export type CardItemProps = {
  card: TCardItemConfig;
} & CardItemStyledProps;
