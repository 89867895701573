export enum Colors {
  generalDark = '#151F32',
  generalDarkHover = '#707C94',
  generalWhite = '#ffffff',
  secondaryDarkGray = '#9AA0AD',
  secondaryGray = '#FAF9FD',
  borderGray = '#E9E6F0',
  grey = '#9AA0AD',
  lightGrey = '#FAF9FD',
  hoverGrey = '#707C94',
  darkGrey = '#7E8B9D',
  green = '#3FB65F',
  lightGreen = '#ECF8EF',
  hoverGreen = '#259A42',
  pink = '#F29993',
  black = '#151f32',
  lightPink = '#FEF5F5',
  hoverPink = '#EB6D65',
  blue = '#0770E3',
  lightBlue = '#E7F1FD',
  hoverBlue = '#0359B7',
  hoverGray = '#F2F1F4'
}
