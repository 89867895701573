export const TypographySchemas = {
    bold72: `
        font-style: normal;
        font-weight: 700;
        font-size: 72px;
        line-height: 108px;
        letter-spacing: -2px;
    `,
    extraBold40: `
        font-style: normal;
        font-weight: 800;
        font-size: 40px;
        line-height: 52px;    
        letter-spacing: -1px;
    `,
    bold32: `
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
    `,
    bold24: `
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;    
        letter-spacing: -0.5px;
    `,
    semi24: `
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.5px;
    `,
    semi16: `
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;    
        letter-spacing: -0.15px;
    `,
    bold14: `
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.2px;
    `,
    semi14: `
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;    
        letter-spacing: -0.15px;
    `,
    reg14: `
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.1px;
    `,
    semibold12: `
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.1px;
    `,
    reg12: `
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    `,
    reg10: `
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
    `,
}
