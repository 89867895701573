import React, {FC} from 'react';
import {ButtonStyledProps, IconWrapper, Wrapper} from './style';
import {IconNames} from '../Icon/Icon.enum';
import {Icon} from '../Icon';

type ButtonProps = {
  icon?: IconNames,
  type: 'button' | 'submit',
  onClick?: (event: any) => void,
  dataTestId?: string
  className?: string
} & ButtonStyledProps

const Button: FC<ButtonProps> = ({
                                   children,
                                   icon,
                                   color,
                                   onClick,
                                   type,
                                   disabled,
                                   dataTestId,
                                     className
}) => {

  const clickHandler = (event: any) => {
    !disabled && onClick && onClick(event);
  }

  return (
    <Wrapper className={className} data-test-id={dataTestId} color={color} onClick={clickHandler} type={type} disabled={disabled}>
      {icon && <IconWrapper>
          <Icon size={"large"} icon={icon} />
      </IconWrapper>}
      {children}
    </Wrapper>
  )
}

export {Button, ButtonProps};
