import { makeAutoObservable } from 'mobx';

import { provide } from '../../../shared/utils/IoC';
import { TModalConfig } from '../components/Modal/Modal';

export interface IModalPayload {
  [key: string]: any;
}

@provide.singleton()
export class ModalsStore {
  private _uniqueKeyToModalArr: Map<string, TModalConfig[]> = new Map<
    string,
    TModalConfig[]
  >();

  private _activeModalId: string | null = null;
  private _modalPayload: IModalPayload | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  getModalList = (uniqueKey: string): TModalConfig[] | null =>
    this._uniqueKeyToModalArr.get(uniqueKey) || null;

  getAllModalsLists = (): TModalConfig[] =>
    Array.from(this._uniqueKeyToModalArr.values()).flatMap((item) => item);

  setModalList = (uniqueKey: string, modalList: TModalConfig[]): void => {
    this._uniqueKeyToModalArr.set(uniqueKey, modalList);
  };

  getActiveModalId = (): string | null => this._activeModalId;

  setActiveModalId = (activeModalId: string) =>
    (this._activeModalId = activeModalId);

  setModalPayload = (payload: IModalPayload) => {
    this._modalPayload = payload;
  };

  getModalPayload = (): IModalPayload | null => this._modalPayload;

  clearModalList = (uniqueKey: string): void => {
    this._uniqueKeyToModalArr.delete(uniqueKey);
  };

  clearUniqueKeyToModal = (): void => {
    this._uniqueKeyToModalArr.clear();
  };

  clearActiveModalId = (): void => {
    this._activeModalId = null;
  };

  clearModalPayload = (): void => {
    this._modalPayload = null;
  };
}
