import { getInitials } from './';

const findSymbol = (data: string[], str: string): boolean =>
  data.includes(getInitials(str.toLowerCase(), 1));

const colorMapping = (prop: string) => {
  const colors = [
    {
      color: '#D97C26',
      background: '#FAEFE5',
      check: (str: string) => findSymbol(['a', 'б', 'в', 'a', 'b', 'c', '1', '$', ','], str),
    },
    {
      color: '#831CA7',
      background: '#F0E3F4',
      check: (str: string) => findSymbol(['г', 'д', 'е', 'd', 'e', 'f', '2', '!', '<', '>'], str),
    },
    {
      color: '#6391C3',
      background: '#ECF1F7',
      check: (str: string) => findSymbol(['ё', 'ж', 'з', 'g', 'h', 'i', '3', '@', '(', ')'], str),
    },
    {
      color: '#D8746E',
      background: '#FAEEED',
      check: (str: string) => findSymbol(['и', 'й', 'к', 'j', 'k', 'l', '4', '#', '?', '.'], str),
    },
    {
      color: '#009FC1',
      background: '#E0F3F7',
      check: (str: string) => findSymbol(['л', 'м', 'н', 'm', 'n', 'o', '5', '№', '*'], str),
    },
    {
      color: '#75A347',
      background: '#EEF4E9',
      check: (str: string) => findSymbol(['о', 'п', 'р', 'p', 'q', 'r', '6', '$', '|', '/'], str),
    },
    {
      color: '#997DBD',
      background: '#F2EFF7',
      check: (str: string) => findSymbol(['с', 'т', 'у', 's', 't', 'u', '7', '~'], str),
    },
    {
      color: '#D4569A',
      background: '#F9EAF3',
      check: (str: string) => findSymbol(['ф', 'х', 'ц', 'v', 'w', 'x', '8', '`', '{', '}'], str),
    },
    {
      color: '#369C96',
      background: '#E6F3F2',
      check: (str: string) => findSymbol(['ц', 'ш', 'щ', 'y', 'z', '9', '%', ':', ';'], str),
    },
    {
      color: '#7C8298',
      background: '#EFF0F2',
      check: (str: string) => findSymbol(['ы', 'э', 'ю', 'я', '0', '&', '[', ']'], str),
    },
  ];

  if (!prop) {
    return colors[0];
  }

  const result = colors.find(({ check }) => check(prop));

  if (!result) {
    return colors[0];
  }

  return result;
};

const getAvatarColor = (str: string) => {
  console.log();
  const { color, background } = colorMapping(str);

  return `
    background: ${background};
    color: ${color};
  `;
};

export default getAvatarColor;
