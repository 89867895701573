import styled from 'styled-components';
import {Colors} from '../configs/Colors';

export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0 0 4px;
  color: ${Colors.secondaryDarkGray};
`

export type FieldStyledProps = {
  isOpened: boolean;
  blocked?: boolean;
  isInGroupRight?: boolean;
  isInGroupLeft?: boolean;
};

const isInGroupRightState = `
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left: none;
`;

const isInGroupLeftState = `
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: none;
`;

const openedState = `
  border-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: transparent;
`;

const closeState = `
  border-radius: 12px;
`;

export const Field = styled.div<FieldStyledProps>`
  background: ${({ blocked}) => blocked ? '#ffffff' : Colors.secondaryGray};
  
  border: 1px solid ${Colors.borderGray};
  ${({isOpened}) => isOpened ? openedState : closeState};
  
  ${({isInGroupLeft}) => isInGroupLeft && isInGroupLeftState}
  ${({isInGroupRight}) => isInGroupRight && isInGroupRightState}
  box-sizing: border-box;
  height: 40px;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    border-color: ${Colors.secondaryDarkGray};
  }
`;

export const Placeholder = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${Colors.secondaryDarkGray}
`;

export const Value = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${Colors.generalDark};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SelectBody = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  border: 1px solid ${Colors.borderGray};
  border-top: none;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background: ${Colors.secondaryGray};
  z-index: 100;
`

export const SelectBodyInnerWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 0.5px solid ${Colors.borderGray};
  background: ${Colors.secondaryGray};
  width: 100%;
  max-height: 160px;
  box-sizing: content-box !important;
  overflow-x: hidden;
  overflow-y: scroll;
  & {
    overflow: -moz-scrollbars-none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

export const Option = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  color: ${Colors.secondaryDarkGray};
  cursor: pointer;
  
  &:hover {
    color: ${Colors.green};
  }
`;

export const ItemText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
`;

export const Error = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: ${Colors.pink};
  padding: 4px 0;
`;

type WrapperStyledProps = {
  isErrorVisible: boolean;
  isOpened: boolean;
  disabled?: boolean;
  blocked?: boolean;
}

export const Wrapper = styled.div<WrapperStyledProps>`
  position: relative;
  outline: none;
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
  pointer-events: ${({disabled, blocked}) => disabled || blocked ? `none` : `auto`};
  
  & * {
    box-sizing: border-box;
  }
  
  ${({ isOpened }) => isOpened && `
    & ${Field} {
          border-color: ${Colors.secondaryDarkGray};
          border-bottom-color: transparent;
    }
    
    & ${SelectBody} {
      border-color: ${Colors.secondaryDarkGray};
    }
  `}
  
  user-select: none;
  ${({isErrorVisible, isOpened }) => isErrorVisible && `
    & ${Field} {
      border-color: ${Colors.pink};
      ${isOpened && 'border-bottom-color: transparent;'}
    }
    
    & ${Value} {
      color: ${Colors.pink}
    } 
    
    & ${SelectBody} {
      border-color: ${Colors.pink};
    }
  `}
`;
