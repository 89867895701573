import React, {FC, useState} from 'react';
import {InputField, ErrorMessage, InputFieldStyledProps, IconWrapper, Wrapper, Label} from './style';
import {Icon} from '../Icon';
import {Colors} from '../configs/Colors';
import ClosedEye from './closed.eye.svg';

export type PasswordProps = {
  label: string;
  value?: string ;
  onChange?: (value: string ) => void;
  placeholder: string;
  dataTestId?: string;
} & InputFieldStyledProps;

export const Password: FC<PasswordProps> = ({ value , label,  error = "", onChange , placeholder, dataTestId}) => {
  const [isPasswordMode, setIsPasswordMode] = useState<boolean>(true);
  const handleChange = (event: { target: { value: React.ReactText; }; }) => {
    if (onChange) {
      onChange(event.target.value.toString());
    }
  };

  const handleChangeMode = () => {
    setIsPasswordMode(!isPasswordMode);
  }

  return (
    <Wrapper>
      <Label>{label}</Label>
      <InputField
          value={value}
          placeholder={placeholder}
          type={isPasswordMode ? 'password' : 'text' }
          error={error}
          onChange={handleChange}
          data-test-id={dataTestId}
      />
      <IconWrapper data-test-id={`${dataTestId}_eye`} onClick={handleChangeMode}>
        {!isPasswordMode ? <Icon size={'small'} fill={Colors.secondaryDarkGray} icon={'eye'}/> : (<img src={ClosedEye}/>)}
      </IconWrapper>
      {error && (<ErrorMessage data-test-id={`${dataTestId}_errorMessage`}>{error}</ErrorMessage>)}
    </Wrapper>
  )
};

