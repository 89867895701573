import React, {FC, ReactElement, useEffect, useState} from 'react';
import {Checkmark, Input, Label, LabelWrapper, Wrapper} from './style';
import CheckSvg from '../shared/assets/icons/check.svg';

export type CheckboxProps = {
  defaultValue?: boolean;
  label?: string | ReactElement;
  onChange: (v: boolean) => void;
  value?: boolean;
  dataTestId?: string;
  disable?: boolean
  className?: string;
  isMarkOnly?: boolean;
}

const Checkbox: FC<CheckboxProps> = ({isMarkOnly, className, label, defaultValue, onChange, value, dataTestId, disable}) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    defaultValue && setIsActive(defaultValue);
  }, [defaultValue])

  useEffect(() => {
    if (value !== undefined) {
      setIsActive(value);
    }
  }, [value]);

  const onChangeHandle = () => {
    if (!disable) {
      onChange(!isActive);
      setIsActive(!isActive);
    }
  }

  const renderLable = () => typeof label === 'string'
    ? <Label isMarkOnly={isMarkOnly}>{label}</Label>
    : <LabelWrapper isMarkOnly={isMarkOnly}>{label}</LabelWrapper>


  return (
    <Wrapper className={className} disable={disable} onClick={onChangeHandle} data-test-id={dataTestId}>
      <Checkmark isActive={isActive} data-test-id={dataTestId+ '-checkbox'}>
        <div>
          <img src={CheckSvg} alt="" />
        </div>
      </Checkmark>
      {renderLable()}
      <Input className={`${className}_input`} data-test-id={dataTestId+ '-input'} checked={isActive} type="checkbox" value={isActive} />
    </Wrapper>
  )
}

export default Checkbox;
