import React, { FC } from 'react';
import withCustomModalComponent from '../../../../../../shared/utils/withCustomModalComponent';

import * as Styled from '../../styles';

export type TModalDescriptionConfig = {
  value: string | FC;
} & Styled.TStyledDescriptionProps;

const Description = (config: TModalDescriptionConfig) => {
  return (
    <Styled.Description {...config}>
      {typeof config.value === 'string'
        ? config.value
        : withCustomModalComponent(config.value)}
    </Styled.Description>
  );
};

export default Description;
