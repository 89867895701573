import styled from 'styled-components';
import { Colors } from '../configs/Colors';

export type ChipStyledProps = {
  size: 'small' | 'medium';
  color: "default" | "primary" | "secondary" | "info";
}

type ColorType = {
  default: Colors;
  hover: Colors;
  light: Colors;
}

interface MappingType {
  [x: string]: ColorType;
}

export const mappingColors: MappingType = {
  default: {
    default: Colors.grey,
    hover: Colors.hoverGrey,
    light: Colors.lightGrey,
  },

  primary: {
    default: Colors.green,
    hover: Colors.hoverGreen,
    light: Colors.lightGreen,
  },

  secondary: {
    default: Colors.pink,
    hover: Colors.hoverPink,
    light: Colors.lightPink,
  },

  info: {
    default: Colors.blue,
    hover: Colors.hoverBlue,
    light: Colors.lightBlue,
  },
}

const renderSize = (size: ChipStyledProps['size']) => {
  const variants: Record<ChipStyledProps['size'], string> = {
    medium: `
      height: 24px;
      font-size: 14px;
      line-height: 20px;
    `,
    small: `
      height: 20px;
      font-size: 12px;
      line-height: 16px;
    `,
  }

  return variants[size];
}

const renderColor = (c: ChipStyledProps['color']) => {
  const variants = Object.keys(mappingColors).reduce((acc, item) => {
    const color = mappingColors[item];

    return {
    ...acc,
    [item]: `
      background-color: ${color.light};
      color: ${color.default};

      svg {
        fill: ${color.default};
      }

      &:hover, &:focus {
        color: ${color.hover};

        svg {
          fill: ${color.hover};
        }
      }
    `
  }}, {} as Record<ChipStyledProps['color'], string>);

  const result = variants[c];

  if (!result) {
    return variants['default'];
  }

  return result;
}

export const IconWrapper = styled.div`
  display: inline-flex; 
  margin-right: 5px;
`;

export const Wrapper = styled.div<ChipStyledProps>`
  display: inline-flex; 
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  white-space: nowrap;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
  user-select: none;
  padding: 2px 8px;

  ${({ size }) => renderSize(size)};
  ${({ color }) => renderColor(color)};
`;
