import styled from "styled-components";
import {Colors} from "../configs/Colors";

export const Wrapper = styled.div<{isOpen: boolean}>`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 12px;
  background: ${Colors.generalWhite};
  border: 0.5px solid ${Colors.borderGray};
  transition: 300ms;
  &:hover {
    ${({isOpen}) => !isOpen && `border-color: ${Colors.secondaryDarkGray};`}
    
  }
  
  * {
    box-sizing: border-box;
    user-select: none;
    transition: all 0.3s ease-in-out;
  }
  
`

export const Body = styled.div<{isOpen: boolean}>`
  width: 100%;
  
  padding-left: 4px;
  padding-right: 4px;
  transition: all 0.3s ease-in-out;
  max-height: 0;
  overflow: hidden;
  background: ${Colors.generalWhite};
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  
  ${({isOpen}) => isOpen && 'max-height: 1000px; padding-bottom: 3px;'};
  
`

export const InnerBody = styled.div<{numRows: number}>`
    padding-top: 8px;
    width: 100%;
    padding-bottom: 8px;
    overflow-y: scroll;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    box-sizing: content-box;
     ${({numRows}) => `max-height: calc(${numRows} * 32px)`};
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ListItem = styled.div<{isActive?: boolean}>`
  width: 100%;
  padding-left: 12px;
  padding-right: 12px;
  height: 32px;
  background: ${Colors.generalWhite};
  position: relative;
  &:hover {
    background: ${Colors.hoverGray};
  }
  
  cursor: pointer;
  display: flex;
  border-radius: 6px;
  align-items: center;
  p {
    color: ${Colors.generalDark};
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    display: inline;
    white-space: nowrap;
    ${({isActive}) => isActive && `color: ${Colors.green}`}
  }
`;

export const Line = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  transition: none;
  border-top: 0.5px solid ${Colors.borderGray};
`;

export const Header = styled.div<{headerHeight: number}>`
  width: 100%;
  height: ${({headerHeight}) => `${headerHeight}px`};
  
  background: ${Colors.generalWhite};
  border-radius: 12px;
  /* padding-top: 8px; */
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Arrow = styled.img<{isOpen: boolean}>`
  height: 6px;
  width: 10px;
  margin-top: 5px;
  ${({isOpen}) => isOpen ? `transform: scale(1, -1)` : ''};  
  
  
`

export const Label = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.generalDark};
`

export const Placeholder = styled.p`
  margin: 0;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.secondaryDarkGray};
`
export const PopupWrapper = styled.div`
  position: absolute;
  height: 40px;
  padding: 12px;
  right: -30px;
`;

export const Popup = styled.div`
  position: relative;
  div {
    position: absolute;
    left: 0;
    transform: rotate(45deg);
  }
`

export const TipWrapperContainer = styled.div`
  position: absolute;
  z-index: 900;
`

export const TipTail = styled.div`
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  left: -5px;
  top: 15px;
  border-right: 5px solid ${Colors.generalWhite};
`;

export const TipWrapper = styled.div`
  position: relative;
  filter: drop-shadow(0px 2px 24px rgba(0, 0, 0, 0.1));
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: ${Colors.generalWhite};
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  
  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: ${Colors.generalDark};
  }
`
