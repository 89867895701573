import React, { FC } from 'react';
import { TypographyWrapper } from "./style";
import {ColorsThemeType} from "../configs/Colors/types";

type ComponentType = 'h1' |
'h2' |
'h3' |
'h4' |
'h5' |
'h6' |
'p' | 'div' | 'span';

export type VariantsType = 'h1' |
'h2' |
'h3' |
'h4' |
'h5' |
'title' |
'bodyAccent' |
'bodySubAccent' |
'body' |
'noteAccent' |
'note' |
'annotation';

export type AlignType = 'center' | 'justify' | 'left' | 'right'

export type TypographyProps = {
  align?: AlignType;
  noWrap?: boolean;
  ellipsis?: boolean;
  variant?: VariantsType;
  component?: ComponentType;
  color?: ColorsThemeType | 'inherit';
  hoverColor?: ColorsThemeType;
  dataTestId?: string;
  className?: string;
  onClick?: () => void;
};

const Typography: FC<TypographyProps> = ({
    align = 'left',
    noWrap = false,
    ellipsis = false,
    variant = 'body',
    component = 'span',
    children,
    color = 'generalDark',
    dataTestId,
    className,
    hoverColor,
    onClick,
}) => {

  return (
      <TypographyWrapper
          className={className}
          data-test-id={dataTestId}
          as={component}
          align={align}
          noWrap={noWrap}
          variant={variant}
          ellipsis={ellipsis}
          color={color}
          hoverColor={hoverColor}
          onClick={onClick}
      >
          {children}
      </TypographyWrapper>
  );
};

export default Typography;
